.tint-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  background: none;
  /* mix-blend-mode: exclusion; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.black-tint {
  border-radius: 10000px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200vw;
  height: 200vw;
  opacity: 1;
  transition: all 0.5s ease;
}

.black-tint.black {
    opacity: 0;
    width: 0;
    height: 0;
}

.project-container {
  background-color: #121212;
  height: 100%;
  width: 100%;
  position: fixed;
}

.black-scene-container {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 1;
}

.color-scene-container {
  width: 100vw;
  height: 100vh;
  /* position: relative; */
  /* position: absolute; */
  /* opacity: 1;  */
}

.color-tint {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    transition: all 0.5s ease;
    /* background-color: red; */
    mix-blend-mode: multiply;
}


.project-info-container.choosing {
  opacity: 0;
  /* color: rebeccapurple; */
}

.project-info-container {
    position: fixed;
    z-index: 1;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 39vh;
    opacity: 1;
    color: #121212;
}
.project-sub {
    font-size: 2.4rem;
    margin-bottom: 108px;
    color: #ffffff;
    font-family: NeueMontreal-L;
    /* margin-top: -3vh; */
}

.project-cat {
    font-size: 1.8rem;
    letter-spacing: 0.5px;
    margin-bottom: 60px;
    color: #ffffff;
}

.project-info-container button {
    perspective: 5000px;
    transform-style: preserve-3d;
    /* transition: transform 0.4s ease; */
    border-radius: 100px;
    display: inline-block;
    position: relative;
    background: none;
    border: none;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    background: transparent;
    border: 1px #ffffff solid;
    margin-bottom: 40px;
    padding: 13px 70px;
    mix-blend-mode: difference;
    transition: box-shadow .25s ease, color 0.2s ease;
}

.project-info-container .hover:hover,
.project-info-container .hover:focus {
  box-shadow: inset 0 -10.6em 0 0 #fff;
  transition: box-shadow .75s ease, color 0.5s ease;
  color: #121212;
}

.coming-soon {
  color: #d1d1d1 !important;
  border: 1px #d1d1d1 solid !important;
  cursor: not-allowed !important;
}