::-webkit-scrollbar {display:none;}

body {
  width: 100vw;
  overflow-x: hidden;
}

.test {
  z-index: 999;
  position: absolute;
}



.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  background-color: white;
  opacity: 1;
  transition: all 0.8s ease;
}

.loader.fade {
  transition: all 0.8s ease;
  opacity: 0;
}

.loader.gone {
  display: none;
}


.logo {
  width: 130px;
  /* pointer-events: none; */
  transition: all 0.3s cubic-bezier(0.22, 0.68, 0, 1);
  cursor: pointer;
  /* border: red 5px solid; */
}

.logo:hover {
  transform: scale(1.05);
}

.logo-container {
  position: fixed;
  top: 0;
  z-index: 11;
  width: 100vw;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: end;
  transition: all 1s cubic-bezier(0.22, 0.68, 0, 1);
}

.logo-container.small {
  width: 220px;
  height: 70px;
  color: red;
}

.logo-container.small .logo {
  width: 120px;
}

.side-cover {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: white;
  z-index: 5;
  left: 0;
  transition: all 0.8s ease-in-out;
}

.side-cover.hidden {
  left: 100vw;
  opacity: 0;
}