@font-face {
  font-family: Gilroy-R;
  src: url("../fonts/Gilroy-Regular.otf") format("opentype");
}

@font-face {
  font-family: NeueMontreal-R;
  src: url("../fonts/NeueMontreal-Regular.otf") format("opentype");
}

@font-face {
  font-family: NeueMontreal-L;
  src: url("../fonts/NeueMontreal-Light.otf") format("opentype");
}

@font-face {
  font-family: NeueMontreal-B;
  src: url("../fonts/NeueMontreal-Bold.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  font-family: NeueMontreal-R;
  box-sizing: border-box;
}