.main-container {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    transition: opacity 500ms ease;
}

.main-container.preserve {
  overflow-y: hidden;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.main-container.auto {
  height: auto;
}

.home-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
}

.scene-container {
  width: 100%;
  height: 100%;
}

.main-container.fade {
  opacity: 0;
}

.intro-overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.235);
  backdrop-filter: blur(15px);
  transition: all 0.25s ease-in;
  padding-top: 20vh;
  /* transition: transform 0.5s ease-in; */
}

.intro-overlay.hide {
  opacity: 0;
  transform: scale(1.2);
}

.intro-overlay.gone {
  display: none;
}

.btn:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.btn {
  transition: transform 0.4s ease;
  border-radius: 100px;
  display: inline-block;
  position: relative;
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  background: transparent;
  border: 1px white solid;
  margin-bottom: 40px;
}
.btn-span {
    border-radius: 100px;
  display: block;
  padding: 13px 70px;
}
/* .btn::before,
.btn::after {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  transition: all 0.15s linear;
  background: #fff;
}

.btn-span::before,
.btn-span::after {
  content: "";
  width: 1px;
  height: 0;
  position: absolute;
  transition: all 0.15s linear;
  background: #fff;
}
.btn:hover::before,
.btn:hover::after {
  width: 100%;
}
.btn:hover .btn-span::before,
.btn:hover .btn-span::after {
  height: 100%;
}

.btn::before,
.btn::after {
  transition-delay: 0s;
}
.btn .btn-span::before,
.btn .btn-span::after {
  transition-delay: 0.2s;
}
.btn::before {
  right: 0;
  top: 0;
}
.btn::after {
  left: 0;
  bottom: 0;
}
.btn .btn-span::before {
  left: 0;
  top: 0;
}
.btn .btn-span::after {
  right: 0;
  bottom: 0;
}
.btn:hover::before,
.btn:hover::after {
  transition-delay: 0.15s;
}
.btn:hover .btn-span::before,
.btn:hover .btn-span::after {
  transition-delay: 0s;
} */

.ripple {
  animation: ripple 0.75s linear;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 1px rgba(255, 255, 255, 0.3), 0 0 0 3px rgba(255, 255, 255, 0.3),
      0 0 0 5px rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 4px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0),
      0 0 0 30px rgba(255, 255, 255, 0);
  }
}

.enter-mute {
  color: white;
  background-color: transparent;
  border: none;
  font-size: 0.9rem;
  transition: 0.5s;
  padding: 4px 0;
  position: relative;
  border-bottom: #c6c6c6 solid 1px;
  cursor: pointer;
}

.enter-mute::after {
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: white;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s;
}

.enter-mute:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.header {
  pointer-events: none;
  margin-bottom: 50px;
}

.intro-overlay p {
  color: white;
  width: 420px;
  text-align: center;
  font-size: 1.15rem;
  letter-spacing: 0.5px;
  line-height: 150%;
  margin-bottom: 80px;
}

.border-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0vw;
  left: 0vw;
  pointer-events: none;
  z-index: 10;
  padding: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border {
  border: 1px white solid;
  border-radius: 10px;
  width: 0px;
  height: 0px;
  opacity: 0;
  box-shadow: 0 0 10px white;
}

.border.full {
  animation: border 0.75s cubic-bezier(0.22, 0.68, 0, 1) forwards;
}

@keyframes border {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
}

.clickdrag {
    position: absolute;
    bottom: 1.7vw;
    width: 100vw;
    text-align: center;
    color: white;
    pointer-events: none;
}

.ui-container {
    width: 100%;
    height: 100%;
    /* border: 1px solid white; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ui-top {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    transition: all 1.5s cubic-bezier(0.22, 0.68, 0, 1);
}
.ui-bottom {
    position: absolute;
    opacity: 0;
    bottom: 0;
    width: 100%;
    transition: all 1.5s cubic-bezier(0.22, 0.68, 0, 1);
}

.show {
    opacity: 0.75;
}

.scroll-container {
    position: absolute;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin-top: 50vh;
    margin-left: 3vw;
}

.option {
    color: white;
    font-size: 80px;
}

.custom-hotspot:hover {
    background-color: red;
}