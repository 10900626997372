.about-bg {
    position: fixed;
    z-index: 5;
    height: 100vh;
    width: 50vw;
    background-color: rgba(154, 154, 154, 0.2);
    backdrop-filter: blur(20px);
    border-right: 1px solid white;
    transition: transform 0.5s ease;
}

.about-bg.slide-in {
    transform: translateX(-105%);
}

.me {
    position: fixed;
    z-index: 8;
    bottom: -30px;
    height: 500px;
    left: 50vw;
    transform: translateX(-50%);
    transition: transform 0.5s ease;
}

.me.slide-up {
    transform: translateX(-50%) translateY(105%);
}

.about-container {
    position: relative;
    z-index: 6;
    display: flex;
    align-items: center;
    width: 50vw;
    padding: 120px 4vw;
    padding-bottom: 300px;
    /* overflow: scroll; */
}

.about-content {
    width: 100%;
    height: 100%;
}

.about-header {
    font-family: NeueMontreal-B;
    font-size: 3.3rem;
    color: white;
}

.about-line {
    width: 100%;
    height: 1px;
    background-color: white;
    margin: 30px 0;
}

.about-text {
    font-family: NeueMontreal-R;
    font-size: 1.2rem;
    color: white;
    line-height: 150%;
    margin-bottom: 30px;
}