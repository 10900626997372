.proj-container {
  padding-top: 45vh;
  padding-bottom: 45vh;
  position: absolute;
  z-index: 10;
  opacity: 0;
  animation: show 1s forwards linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  pointer-events: none;
}

@keyframes show {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.proj-textItem {
  margin: 0;
  padding: 0px 0px;
  /* border: 1px solid white; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 1rem; */
  color: #d1d1d1;
  font-size: 85px;
  line-height: 110%;
  font-family: NeueMontreal-L;
  border-radius: 5px;
  position: relative;
  transition: transform 0.25s ease;
  transition: opacity 0.25s ease;
  transition: color 0.5s ease;
}

/* .textItem:hover {
  transform: scale(1.025);
} */

.proj-textItem.hidden {
  opacity: 0 !important;
}


.goTo {
  cursor: pointer;
  border: none;
  width: 100%;
  position: absolute;
  height: 100%;
  background: transparent;
}

.proj-textItem.selected {
  /* transition: color 0s; */
  /* color: #121212 !important; */
}