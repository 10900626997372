.nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 15;
    /* height: 80px; */
    padding: 20px 1.4vw;
    display: flex;
    align-items: end;
    justify-content: space-between;
    transition: all 0.5s ease;
}

.nav.black {
    filter: invert(1);
}

.nav-menu-button {
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
    transition: transform 0.2s ease;
    z-index: 15;
}

.nav-menu-button:hover {
    transform: scale(1.075);
}

.nav-menu-line {
    height: 2.5px;
    border-radius: 100px;
    background-color: #121212;
    width: 20px;
    margin-right: 2px;
    transition: transform 0.2s ease;
}

.nav-menu-line.bottom {
    margin-right: -2px;
}

.nav-menu-button.open .nav-menu-line {
    transform: rotate(45deg) translateX(3.35px) translateY(1.5px);
}

.nav-menu-button.open .nav-menu-line.bottom {
    transform: rotate(-45deg) translateX(2px) translateY(-3px);
}

.nav-popup {
    position: fixed;
    /* background-color: rgba(255, 255, 255, 1); */
    top: 80px;
    right: 1.4vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0px 5px;
}

.nav-popup-cont {
    display: flex;
    flex-direction: column;
    align-items: end;
    row-gap: 20px;
    transition: transform 0.6s ease;
}

.nav-popup-cont.hide {
    transform: translateY(-100%);
}

.nav-link {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 2rem;
    font-family: NeueMontreal-L;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.nav-link:hover {
    transform: scale(1.05);
}